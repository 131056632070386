// Input Button + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         0.75rem;
$input-btn-padding-x:         1.5625rem;
$input-btn-font-size:         $h6-font-size;
$input-btn-line-height:       1.88;

$input-btn-focus-width:       .2rem;
$input-btn-focus-color:       rgba($primary, .25);
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-font-size-sm:      $font-size-sm;
$input-btn-line-height-sm:    1.2;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-font-size-lg:      $font-size-lg;
// $input-btn-line-height-lg:    $line-height-lg;

// $input-btn-border-width:      $border-width;


$input-border-radius:         8px; //8px
// $input-border-radius-lg:                $border-radius-lg;
// $input-border-radius-sm:                $border-radius-sm;


// $input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
// $input-height-sm:                       add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
// $input-height-lg:                       add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;