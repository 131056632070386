

// Buttons
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x;
$btn-font-size:               $input-btn-font-size;
$btn-line-height:             $input-btn-line-height;
//$btn-white-space:             null; // Set to `nowrap` to prevent text wrapping

// $btn-padding-y-sm:            $input-btn-padding-y-sm;
// $btn-padding-x-sm:            $input-btn-padding-x-sm;
// $btn-font-size-sm:            $input-btn-font-size-sm;
// $btn-line-height-sm:          $input-btn-line-height-sm;

// $btn-padding-y-lg:            $input-btn-padding-y-lg;
// $btn-padding-x-lg:            $input-btn-padding-x-lg;
// $btn-font-size-lg:            $input-btn-font-size-lg;
// $btn-line-height-lg:          $input-btn-line-height-lg;

// $btn-border-width:            $input-btn-border-width;


$btn-font-weight:             700;
// $btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075);
// $btn-focus-width:             $input-btn-focus-width;
// $btn-focus-box-shadow:        $input-btn-focus-box-shadow;
// $btn-disabled-opacity:        .65;
// $btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125);

// $btn-link-disabled-color:     $gray-600;

// $btn-block-spacing-y:         .5rem;


$btn-border-radius:           8px;
// $btn-border-radius-lg:        $border-radius-lg;
// $btn-border-radius-sm:        $border-radius-sm;

$btn-transition:              .4s cubic-bezier(0.39, 0.575, 0.565, 1);

// $btn-focus-box-shadow:        none!important;